import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/Shared/Breadcrumb';

class Faq extends PureComponent {
  render() {
    return (
      <>
        <Breadcrumb>
          <h2>Got Questions?</h2>
          <ul className="bread-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <i className="icofont-simple-right" />
            </li>
            <li className="active">FAQ</li>
          </ul>
        </Breadcrumb>
        <section className="faq section">
          <Helmet title="FAQ" />
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-12 mt-3">
                  <h3>What is Durable Medical Equipment (DME)?</h3>
                  <p>
                    Medical equipment that is ordered by a doctor for use in the home. A hospital or nursing home that
                    mostly provides skilled care can&apos;t qualify as a “home” in this situation. DME means equipment
                    that:
                    <ul className="mt-2 ml-3">
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Can withstand repeated use
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Is used primarily and customarily to serve a medical purpose
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Generally is not useful to a person in the absence of illness or injury
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Is appropriate for use in the home
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>What products and equipment services does a DME provide?</h3>
                  <p>
                    DME includes a wide range of products, such as:
                    <ul className="mt-2 ml-3">
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Alternating pressure pads and mattresses (also known as support surfaces)
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Blood glucose monitors or meters
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Canes
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Commodes
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Crutches
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Hospital beds (for home use)
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Infusion pumps
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Walkers
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Wheelchairs
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>Who pays for the equipment?</h3>
                  <p>
                    After verifying your insurance coverage, obtaining your approval, and receiving the necessary
                    documentation, we will submit a claim to your insurance provider for reimbursement. Here&apos;s how
                    process works:
                    <ul className="mt-2 ml-3">
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        <strong>Medicare claims:</strong> We&apos;ll file a claim, and if approved, Medicare will cover
                        up to 80% of the product cost (minus any deductible). Your secondary provider may cover the
                        remaining 20%, if you have secondary coverage. Otherwise, you&apos;ll be responsible for the
                        20%.
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        <strong>Private insurance:</strong> Benefits and reimbursement vary depending on your individual
                        plan, program type, deductibles, and eligibility. We recommend contacting us before placing an
                        order to understand your specific coverage.
                      </li>
                    </ul>
                    Remember, reimbursement terms may differ based on your unique insurance situation.
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>Are all your products covered by Medicare and/or private insurance?</h3>
                  <p>
                    Our products meet Medicare&apos;s standards for potential reimbursement, but the extent of coverage
                    varies depending on your specific insurance plan. We&apos;ll help facilitate the claims process with
                    Medicare and/or private insurance providers by gathering and submitting the necessary documentation.
                    While we can&apos;t guarantee approval, we&apos;ll work closely with you to ensure all paperwork is
                    in order, increasing the likelihood of a successful claim. Ultimately, Medicare and/or your private
                    insurance provider will make the final decision on reimbursement.
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>What is prior authorization?</h3>
                  <p>
                    Prior authorization is a process through which a request for provisional affirmation of coverage is
                    submitted for review before a DMEPOS item is furnished to a beneficiary and before a claim is
                    submitted for payment. Prior authorization helps ensure that applicable coverage, payment, and
                    coding rules are met before items are delivered.
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>How will Ultra Care DME use my health information once I provide it?</h3>
                  <p>
                    When you share your health information with Ultra Care DME, it&apos;s used to facilitate your
                    requests for durable medical equipment (DME) or related services. Here&apos;s what typically
                    happens:
                    <ul className="mt-2 ml-3">
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Processing: Ultra Care DME processes your information to assess your needs and determine the
                        appropriate equipment or services.
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Documentation: Your information is documented in your file to maintain accurate records.
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Compliance: Your information is used to ensure compliance with regulatory requirements, such as
                        those related to insurance coverage or medical necessity.
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Communication: Your information may be shared with healthcare providers, insurance companies, or
                        other authorized parties to coordinate care or process claims.
                      </li>
                      <li>
                        <i className="fa fa-caret-right mr-1" />
                        Confidentiality: Ultra Care DME is obligated to protect your health information in accordance
                        with the Health Insurance Portability and Accountability Act (HIPAA) and other applicable laws.
                      </li>
                    </ul>
                    Remember to review Ultra Care DME&apos;s privacy policy and ask questions if you have concerns about
                    how your information will be used or shared.
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>What is the difference between durable medical equipment and medical supplies?</h3>
                  <p>
                    Durable Medical Equipment (DME) consists of items built for extended use, whereas medical supplies
                    are generally single-use or short-term products. However, some insurance plans may classify certain
                    disposable medical supplies as DME, making them eligible for coverage under the DME category.
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>I have questions about billing for my medical equipment and supplies. Who should I contact?</h3>
                  <p>
                    Please call our customer service phone number at (956) 591-7677. You may contact us anytime during
                    normal business hours (Mon – Fri. 8:00 am to 5:00 pm CST). If after hours, or all of our agents are
                    busy. Please leave a message and one of our customer representatives will return your call within 24
                    hours.
                  </p>
                </div>
                <div className="col-12 mt-3">
                  <h3>How do I update my personal information if it&apos;s changed?</h3>
                  <p>
                    If your personal information has changed, such as your physical address, phone number, insurance
                    provider, or medical condition. To ensure we have your latest details on file, call our customer
                    service at (956) 591-7677 during business hours (Monday to Friday, 8am to 5pm CST). If you call
                    after hours or when all agents are busy, leave a message and we&apos;ll return your call within 24
                    hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Faq;
