import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ScrollToTop from 'react-scroll-up';

import Navigation from './Navigation';

// eslint-disable-next-line react/prefer-stateless-function
class Header extends Component {
  constructor() {
    super();

    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      headerClass: '',
      headerInnerClass: ''
    };
  }

  componentDidMount() {
    try {
      window.addEventListener('scroll', this.handleScroll);
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener('scroll', this.handleScroll);
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  }

  handleScroll() {
    const scroll = window.scrollY;

    this.setState({
      headerInnerClass: scroll > 100 ? 'sticky' : '',
      headerClass: scroll > 200 ? 'sticky' : ''
    });
  }

  render() {
    const { headerInnerClass, headerClass } = this.state;
    return (
      <header className={`header ${headerClass}`}>
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <ul className="top-contact">
                  <li>
                    <i className="fa fa-phone" />
                    +1 (956) 591-7677
                  </li>
                  <li>
                    <i className="fa fa-fax" />
                    +1 (956) 766-7289
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <ul className="top-contact" style={{ float: 'right' }}>
                  <li>
                    <i className="fa fa-envelope" />
                    <a href="mailto:info@ultracaredme.com" target="_blank" rel="noreferrer">
                      info@ultracaredme.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={`header-inner ${headerInnerClass}`}>
          <Navigation role="navigation" />
        </div>
        <ScrollToTop showUnder={160} duration={500}>
          <div className="scrollUp">
            <span>
              <i className="fa fa-angle-up" />
            </span>
          </div>
        </ScrollToTop>
      </header>
    );
  }
}

export default Header;
