import React from 'react';

import SectionContent from './SectionContent';
import SectionTitle from './SectionTitle';

function Section(props) {
  const { className, children } = props;

  const controls = Array.isArray(children) ? children : [children];
  const content = controls.find((c) => c.type === SectionContent);
  const title = controls.find((c) => c.type === SectionTitle);

  return (
    <section className={`${className} section`}>
      {title || <div />}
      {content || <div />}
    </section>
  );
}

export default Section;
