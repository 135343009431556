import React from 'react';
import { Link } from 'react-router-dom';

function PortfolioImage(props) {
  const { className, image, alt, to } = props;

  return (
    <div className={className}>
      <img src={image} alt={alt} />
      <Link to={to} className="btn">
        View Details
      </Link>
    </div>
  );
}

export default PortfolioImage;
