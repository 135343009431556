import { isEmpty, isMobilePhone } from 'validator';
import * as Yup from 'yup';

export const defaultRequiredMessage = 'Please enter your phone number';
export const defaultValidityMessage = 'Please enter a valid 10 digit phone number without the country code';

export const validatePhoneNumberValue = (value) => {
  if (!value || value === '') {
    return false;
  }

  let newVal = value.replace(/#/g, '');
  newVal = newVal.replace(/-/g, '');

  if (newVal.length < 10) {
    return false;
  }
  // The following validation should be the same as the backend validation in joi validation
  return !isEmpty(value, { ignore_whitespace: true }) && isMobilePhone(value, 'en-US', { strictMode: false });
};

export const validateDependantPhoneNumber = (
  otherFieldName,
  requiredMessage = defaultRequiredMessage,
  validityMessaage = defaultValidityMessage
) =>
  Yup.string().when(otherFieldName, {
    is: (value) => !value || isEmpty(value, { ignore_whitespace: true }),
    then: (schema) =>
      schema.required(requiredMessage).test({
        name: 'Dependant-PhoneNumber',
        message: validityMessaage,
        test(value) {
          return validatePhoneNumberValue(value);
        }
      }),
    otherwise: (schema) => schema.optional()
  });

// eslint-disable-next-line import/prefer-default-export
export const validatePhoneNumber = (
  requiredMessage = defaultRequiredMessage,
  validityMessaage = defaultValidityMessage
) =>
  Yup.string()
    .required(requiredMessage)
    .test({
      name: 'PhoneNumber',
      message: validityMessaage,
      test(value) {
        return validatePhoneNumberValue(value);
      }
    });
