import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/Shared/Breadcrumb';

class About extends PureComponent {
  async componentDidMount() {
    try {
      // eslint-disable-next-line no-console
      console.log('Info: Home.componentDidMount()');
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  }

  render() {
    return (
      <>
        <Breadcrumb>
          <h2>Get To Know Us</h2>
          <ul className="bread-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <i className="icofont-simple-right" />
            </li>
            <li className="active">About Us</li>
          </ul>
        </Breadcrumb>
        <section className="about-us section">
          <Helmet title="About Us" />
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-12">
                  <h3>Our Mission</h3>
                  <p>
                    At Ultra Care DME, our mission is to serve the needs of our clients, customers, and community with
                    the highest levels of empathy and care.
                  </p>
                </div>
                <div className="col-12">
                  <h3>Our Core Values</h3>
                  <div className="container">
                    <div className="row">
                      <div className="col-6">
                        <ul className="list">
                          <li>
                            <i className="fa fa-caret-right" />
                            Provide the highest quality products and services
                          </li>
                          <li>
                            <i className="fa fa-caret-right" />
                            Efficient and timely execution of services
                          </li>
                          <li>
                            <i className="fa fa-caret-right" />
                            Improve the lives of our customers
                          </li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <ul className="list">
                          <li>
                            <i className="fa fa-caret-right" />
                            Support and advocate for our clients and customers
                          </li>
                          <li>
                            <i className="fa fa-caret-right" />
                            Be a positive force in our community
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p />
                </div>
                <div className="col-12">
                  <h3>Our Vision</h3>
                  <p>
                    Our goal is to improve the fulfillment process for our referral sources, all the while trying to
                    improve the lives of our patients. For us, this means providing the highest quality products
                    available in the market and being efficient and timely when providing our services. We work
                    diligently on behalf of our patients at every step of the way. We use the latest technologies, and
                    proven techniques, to provide the best products and services available in our industry. Our
                    commitment doesn&apos;t end there; we strive to be a strong, positive, and caring force in our
                    community through various outreach programs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default About;
