/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import OwlCarousel from 'react-owl-carousel';

function HearoSlider(props) {
  const { children } = props;

  return (
    <section className="slider">
      <OwlCarousel
        className="hero-slider"
        loop
        items={1}
        autoplay
        autoplayTimeout={3500}
        smartSpeed={500}
        autoplayHoverPause
        nav
        dots={false}
        navText={[
          '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          '<i class="fa fa-angle-right" aria-hidden="true"></i>'
        ]}
      >
        {children}
      </OwlCarousel>
    </section>
  );
}

export default HearoSlider;
