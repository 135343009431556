import React from 'react';

function FunFact(props) {
  const { className, iconClass, children } = props;

  return (
    <div className={className}>
      <div className="single-fun">
        <i className={iconClass} />
        {children}
      </div>
    </div>
  );
}

export default FunFact;
