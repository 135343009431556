import React from 'react';
import { Link } from 'react-router-dom';

function CallToAction(props) {
  const { to, buttonText, children } = props;

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="content">
            {children}
            <div className="button">
              <Link to={to} className="btn">
                {buttonText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
