import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ambulatoryAids from '../../images/products/ambulatory-aids.jpeg';
import bathroomSafety from '../../images/products/bathroom-safety.jpeg';
import hospitalBeds from '../../images/products/hospital-beds.jpeg';
import incontinenceSupplies from '../../images/products/incontinence-supplies.png';
import mobility from '../../images/products/mobility-wheelchairs.jpeg';
import nutritionalSupplements from '../../images/products/nutritional-supplements.png';
import patientAdls from '../../images/products/patient-alds.png';
// import safety from '../../images/products/safety-belt.jpeg';

import PortfolioImage from '../../components/Shared/PortfolioImage';
import SectionContent from '../../components/Shared/SectionContent';
import ScrollingTiles from '../../components/Shared/ScrollingTiles';
import ScrollingTile from '../../components/Shared/ScrollingTile';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Section from '../../components/Shared/Section';

class Products extends PureComponent {
  render() {
    return (
      <>
        <Helmet title="Products" />
        <Breadcrumb>
          <h2>Product Catalog</h2>
          <ul className="bread-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <i className="icofont-simple-right" />
            </li>
            <li className="active">Products</li>
          </ul>
        </Breadcrumb>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <Section className="portfolio">
                  <SectionContent fluid>
                    <div className="col-lg-12 col-12">
                      <ScrollingTiles className="portfolio-slider">
                        <ScrollingTile>
                          <PortfolioImage image={ambulatoryAids} alt="Ambulatory Aids" to="/products/ambulatory-aids" />
                        </ScrollingTile>
                        <ScrollingTile>
                          <PortfolioImage image={bathroomSafety} alt="Bathroom Safety" to="/products/bathroom-safety" />
                        </ScrollingTile>
                        <ScrollingTile>
                          <PortfolioImage image={hospitalBeds} alt="Hospital Beds" to="/products/hospital-beds" />
                        </ScrollingTile>
                        <ScrollingTile>
                          <PortfolioImage
                            image={incontinenceSupplies}
                            alt="Incontinence Supplies"
                            to="/products/incontinence-supplies"
                          />
                        </ScrollingTile>
                        <ScrollingTile>
                          <PortfolioImage image={mobility} alt="Mobility" to="/products/mobility" />
                        </ScrollingTile>
                        <ScrollingTile>
                          <PortfolioImage
                            image={nutritionalSupplements}
                            alt="Nutritional Supplements"
                            to="/products/nutritional-supplements"
                          />
                        </ScrollingTile>
                        <ScrollingTile>
                          <PortfolioImage image={patientAdls} alt="Patient ALDS" to="/products/patient-alds" />
                        </ScrollingTile>
                        {/* <ScrollingTile>
                          <PortfolioImage image={safety} alt="Safety" to="/products/safety" />
                        </ScrollingTile> */}
                      </ScrollingTiles>
                    </div>
                  </SectionContent>
                </Section>
              </div>
            </div>
          </div>
        </section>
        <Section className="services">
          <SectionContent>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-crutch" />
                <h4>
                  <Link to="/products/ambulatory-aids">Ambulatory Aids</Link>
                </h4>
                <p>Single tip canes, small/large base quad canes, walkers, and rollators.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-bathtub" />
                <h4>
                  <Link to="/products/bathroom-safety">Bathroom Safety</Link>
                </h4>
                <p>Shower chairs, benches, shower wands, bedside commodes and more.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-bed" />
                <h4>
                  <Link to="/products/hospital-beds">Hospital Beds</Link>
                </h4>
                <p>
                  Semi/full electric Hopsital beds, gel mattress overlays, trapeze bars, hydraulic lifts, and overbed
                  tables.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-heart" />
                <h4>
                  <Link to="/products/incontinence-supplies">Incontinence Supplies</Link>
                </h4>
                <p>Diapers, pulll ups, poise pads, disposable/reusable underpads, and wipes for infants and adults.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-wheelchair" />
                <h4>
                  <Link to="/products/mobility">Mobility</Link>
                </h4>
                <p>
                  Transport, standard, light wieght, and heavy duty wheelchaairs as well as programmable and
                  non-programmable power wheelchairs and 3/4 wheel scooters.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-apple" />
                <h4>
                  <Link to="/products/nutritional-supplements">Nutritional Supplements</Link>
                </h4>
                <p>Ensure, Ensure with fiber, Ensure pudding, Glucerna, and Nepro.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-heartbeat" />
                <h4>
                  <Link to="/products/patient-adls">Patient ADLS</Link>
                </h4>
                <p>
                  Digital blood pressure monitors, glucometers, lancets, test strips, gait belts, 3-1 lift chirs, and
                  heavy duty lift chairs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-safety" />
                <h4>
                  <Link to="/products/safety">Safety Products</Link>
                </h4>
                <p>Powder free latex gloves and back support belts in all sizes.</p>
              </div>
            </div>
          </SectionContent>
        </Section>
      </>
    );
  }
}

export default Products;
