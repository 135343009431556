import React from 'react';

function FeatureHeader(props) {
  const { children } = props;

  return (
    <div className="col-lg-12">
      <div className="section-title">{children}</div>
    </div>
  );
}

export default FeatureHeader;
