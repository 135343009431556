import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/logo.png';

// eslint-disable-next-line react/prefer-stateless-function
class Navigation extends Component {
  render() {
    return (
      <div className="container">
        <div className="inner">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="Ultra Care DME" />
                </Link>
              </div>
              <div className="mobile-nav" />
            </div>
            <div className="col-lg-7 col-12">
              <div className="main-menu">
                <nav className="navigation">
                  <ul className="nav menu">
                    <li className="active">
                      <Link to="/">
                        Home <i className="icofont-rounded-down" />
                      </Link>
                      <ul className="dropdown">
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/faq">FAQ</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/products">Products </Link>
                    </li>
                    <li>
                      <Link to="/services">Services </Link>
                    </li>
                    <li>
                      <Link to="/insurance">Insurance </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-2 col-12">
              <div className="get-quote">
                <Link to="/contact" className="btn">
                  Send Us An Email
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navigation;
