import React from 'react';

function FunFactRow(props) {
  const { children } = props;

  return (
    <div id="fun-facts" className="fun-facts section overlay">
      <div className="container">
        <div className="row">{children}</div>
      </div>
    </div>
  );
}

export default FunFactRow;
