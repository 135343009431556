import React from 'react';

function InfoBox(props) {
  const { className, innerClassName, iconClass, children } = props;

  return (
    <div className={className}>
      <div className={`single-schedule ${innerClassName}`}>
        <div className="inner">
          <div className="icon">
            <i className={iconClass} />
          </div>
          <div className="single-content">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default InfoBox;
