import React from 'react';

function SectionContent(props) {
  const { fluid = false, children } = props;

  return (
    <div className={fluid ? 'container-fluid' : 'container'}>
      <div className="row">{children}</div>
    </div>
  );
}

export default SectionContent;
