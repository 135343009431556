import React from 'react';

import FeatureHeader from './FeatureHeader';
import Feature from './Feature';

function FeatureRow(props) {
  const { children } = props;

  const controls = Array.isArray(children) ? children : [children];
  const header = controls.find((c) => c.type === FeatureHeader);
  const features = controls.filter((c) => c.type === Feature);

  return (
    <section className="Feautes section">
      <div className="container">
        {header && <div className="row">{header}</div>}
        {features && <div className="row">{features}</div>}
      </div>
    </section>
  );
}

export default FeatureRow;
