import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  async componentDidMount() {
    try {
      // eslint-disable-next-line no-console
      console.log('Info: Footer.componentDidMount()');
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  }

  render() {
    return (
      <footer id="footer" className="footer ">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-4 col-12">
                <div className="single-footer">
                  <h2>Connect With Us</h2>
                  <p>
                    Find us on the social networks below and keep up with everything that is happening at Ultra Care DME
                  </p>
                  <ul className="social">
                    <li>
                      <a href="https://www.facebook.com/ultracaredme" target="_blank" rel="noreferrer">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/ultracaredme/" target="_blank" rel="noreferrer">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/UltraCareDME" target="_blank" rel="noreferrer">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12">
                <div className="single-footer f-link">
                  <h2>Quick Links</h2>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <ul>
                        <li>
                          <Link to="/">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            Contact Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/faq">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            FAQ
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <ul>
                        <li>
                          <Link to="/products">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            Products
                          </Link>
                        </li>
                        <li>
                          <Link to="/services">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            Services
                          </Link>
                        </li>
                        <li>
                          <Link to="/insurance">
                            <i className="fa fa-caret-right" aria-hidden="true" />
                            Insurance
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-12">
                <div className="single-footer">
                  <h2>Open Hours</h2>
                  <ul className="time-sidual">
                    <li className="day">
                      Monday <span>8:00 am - 5:00 pm</span>
                    </li>
                    <li className="day">
                      Tuesday <span>8:00 am - 5:00 pm</span>
                    </li>
                    <li className="day">
                      Wednesday <span>8:00 am - 5:00 pm</span>
                    </li>
                    <li className="day">
                      Thursday <span>8:00 am - 5:00 pm</span>
                    </li>
                    <li className="day">
                      Friday <span>8:00 am - 5:00 pm</span>
                    </li>
                    <li className="day">
                      Saturday <span>CLOSED</span>
                    </li>
                    <li className="day">
                      Sunday <span>CLOSED</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="copyright-content">
                  <p>
                    © Copyright 2023 | All Rights Reserved by <Link to="/">ultracaredme.com</Link>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
