import React from 'react';

import { useCountUp } from 'react-countup';
import { Waypoint } from 'react-waypoint';

function CountUpOnVisible(props) {
  const { value, children } = props;

  const countUpRef = React.useRef(null);
  const { start } = useCountUp({ ref: countUpRef, end: value, suffix: '+' });

  return (
    <Waypoint onEnter={start}>
      <div className="content">
        <span ref={countUpRef} className="counter">
          {value}
        </span>
        {children}
      </div>
    </Waypoint>
  );
}

export default CountUpOnVisible;
