import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ambulatoryAids from '../../images/products/ambulatory-aids.jpeg';
import bathroomSafety from '../../images/products/bathroom-safety.jpeg';
import hospitalBeds from '../../images/products/hospital-beds.jpeg';
import incontinenceSupplies from '../../images/products/incontinence-supplies.png';
import mobility from '../../images/products/mobility-wheelchairs.jpeg';
import nutritionalSupplements from '../../images/products/nutritional-supplements.png';
import patientAdls from '../../images/products/patient-alds.png';
// import safety from '../../images/products/safety-belt.jpeg';

import CountUpOnVisible from '../../components/Shared/CountUpOnVisible';
import FeatureHeader from '../../components/Shared/FeatureHeader';
import FunFactRow from '../../components/Shared/FunFactsRow';
import HearoSlider from '../../components/Shared/HeroSlider';
// import Preloader from '../../components/Shared/Preloader';
import FeatureRow from '../../components/Shared/FeatureRow';
import HeroSlide from '../../components/Shared/HeroSlide';
import FunFact from '../../components/Shared/FunFact';
import Feature from '../../components/Shared/Feature';
import InfoRow from '../../components/Shared/InfoRow';
import InfoBox from '../../components/Shared/InfoBox';
import SectionTitle from '../../components/Shared/SectionTitle';
import SectionContent from '../../components/Shared/SectionContent';
import Section from '../../components/Shared/Section';

import ctaBackgroundImage from '../../images/call-bg.jpg';
import medicaid from '../../images/insurance/medicaid.png';
import driscoll from '../../images/insurance/driscoll.png';
import medicare from '../../images/insurance/medicare.png';
import molina from '../../images/insurance/molina.png';
import superior from '../../images/insurance/superior.png';
import uhc from '../../images/insurance/uhc.png';
import ParallaxSection from '../../components/Shared/ParallaxSection';
import CallToAction from '../../components/Shared/CallToAction';
import ScrollingTile from '../../components/Shared/ScrollingTile';
import PortfolioImage from '../../components/Shared/PortfolioImage';
import ScrollingTiles from '../../components/Shared/ScrollingTiles';
import ContactUsForm from '../../components/Shared/ContactUsForm';
import InsuranceSlider from '../../components/Shared/InsuranceSlider';

function Home() {
  return (
    <div>
      <Helmet title="Home" />
      {/* <Preloader /> */}

      <HearoSlider>
        <HeroSlide backgroundImage='url("img/slider1.jpg")'>
          <h1>
            We Work With Your <span>Doctor</span>
          </h1>
          <p>
            Our professional staff will work with your doctor to ensure that you get the medical equipment and supplies
            as quickly as possible.
          </p>
          <div className="button">
            <Link to="/contact" className="btn">
              Let&apos;s Chat
            </Link>
          </div>
        </HeroSlide>
        <HeroSlide backgroundImage='url("img/slider4.jpg")'>
          <h1>
            We Provide The <span>Highest Quality</span> Medical Equipment And Supplies
          </h1>
          <p>
            Our procurement staff works dillegently on your behalf to source the highest quality medical equipment and
            supplies. We do what other DMEs won&apos;t. We would rather make less profit than provide our customers with
            inferior products.
          </p>
          <div className="button">
            <Link to="/contact" className="btn">
              Let&apos;s Chat
            </Link>
          </div>
        </HeroSlide>
        <HeroSlide backgroundImage='url("img/slider5.jpg")'>
          <h1>
            We Are The <span>DME</span> You Can <span>Trust!</span>
          </h1>
          <p>
            The Ultra Care DME family holds itself to the highest standards. We exist to serve our community. We will
            always do what&apos;s right and what&apos;s best for our customers.
          </p>
          <div className="button">
            <Link to="/contact" className="btn">
              Let&apos;s Chat
            </Link>
          </div>
        </HeroSlide>
      </HearoSlider>

      <InfoRow>
        <InfoBox className="col-lg-4 col-md-4 col-12" innerClassName="first" iconClass="fa fa-ambulance">
          <span>Products / Services</span>
          <h4>Quality Products</h4>
          <p>
            Our staff sources only the best, highest quality, products for our customers. We will not cut corners. If
            there&apos;s a better product available, we&apos;ll get it.
          </p>
          <Link to="/products">
            LEARN MORE
            <i className="fa fa-long-arrow-right" />
          </Link>
        </InfoBox>
        <InfoBox className="col-lg-4 col-md-4 col-12" innerClassName="middle" iconClass="icofont-prescription">
          <span>Insurace</span>
          <h4>Most Isurances Accepted</h4>
          <p>We accept most insurance plans including Medicaid and Medicare</p>
          <Link to="/insurance">
            LEARN MORE
            <i className="fa fa-long-arrow-right" />
          </Link>
        </InfoBox>
        <InfoBox className="col-lg-4 col-md-4 col-12" innerClassName="last" iconClass="icofont-ui-clock">
          <h4>Hours of Operation</h4>
          <ul className="time-sidual">
            <li className="day">
              Monday <span>8:00 AM - 5:00 PM</span>
            </li>
            <li className="day">
              Tuesday <span>8:00 AM - 5:00 PM</span>
            </li>
            <li className="day">
              Wednesday <span>8:00 AM - 5:00 PM</span>
            </li>
            <li className="day">
              Thursday <span>8:00 AM - 5:00 PM</span>
            </li>
            <li className="day">
              Friday <span>8:00 AM - 5:00 PM</span>
            </li>
            <li className="day">
              Saturday <span>CLOSED</span>
            </li>
            <li className="day">
              Sunday <span>CLOSED</span>
            </li>
          </ul>
        </InfoBox>
      </InfoRow>

      <FeatureRow>
        <FeatureHeader>
          <h2>We Are Always Ready to Help You & Your Family</h2>
          <img src="img/section-img.png" alt="#" />
          <p>Our office is conveniently located on Bentsen Palm Drive in Palmview, Tx. Stop by anytime.</p>
          <br />
          <h4>Walk-ins are always welcome!</h4>
        </FeatureHeader>
        <Feature className="col-lg-4 col-12" iconClass="icofont icofont-free-delivery">
          <h3>Free Deliveries</h3>
          <p>All of our products are delivered free of charge to our customers.</p>
        </Feature>
        <Feature className="col-lg-4 col-12" iconClass="icofont icofont-law-document">
          <h3>Training / Support</h3>
          <p>We provide training and support for all of our product at no cost to the customer.</p>
        </Feature>
        <Feature className="col-lg-4 col-12" innerClassName="last" iconClass="icofont icofont-runner-alt-1">
          <h3>Efficient Processing</h3>
          <p>
            We use the latest technologies to ensure a speedy process. Our team has developed proprietary software that
            allows us to improve the fulfillment process.
          </p>
        </Feature>
      </FeatureRow>

      <FunFactRow>
        {/* <FunFact className="col-lg-3 col-md-6 col-12" iconClass="icofont-simple-smile">
          <CountUpOnVisible value={4379}>
            <p>Happy Patients</p>
          </CountUpOnVisible>
        </FunFact> */}
        <FunFact className="col-lg-4 col-md-6 col-12" iconClass="icofont icofont-home">
          <CountUpOnVisible value={100}>
            <p>Products Offered</p>
          </CountUpOnVisible>
        </FunFact>
        <FunFact className="col-lg-4 col-md-6 col-12" iconClass="icofont icofont-user-alt-3">
          <CountUpOnVisible value={7}>
            <p>Insurances Accepted</p>
          </CountUpOnVisible>
        </FunFact>
        <FunFact className="col-lg-4 col-md-6 col-12" iconClass="icofont icofont-table">
          <CountUpOnVisible value={25}>
            <p>Years of Experience</p>
          </CountUpOnVisible>
        </FunFact>
      </FunFactRow>

      <Section className="why-choose">
        <SectionTitle>
          <h2>We Offer Different Products and Services To Improve Your Health</h2>
          <img src="img/section-img.png" alt="#" />
          <p>
            We offer a long list of products and services to help improve the life our customers. If you don&apos;t see
            something you need in our catalog, let us know and we&apos;ll do our best to get it for you.
          </p>
        </SectionTitle>
        <SectionContent>
          <div className="col-lg-6 col-12">
            <div className="choose-left">
              <h3>Our Mission</h3>
              <p>
                At Ultra Care DME, our mission is to serve the needs of our clients, customers, and community with the
                highest levels of empathy and care.
              </p>
              <p>
                This is embeded in our DNA. Everything we do is done to advance this mission and aling with our core
                values.
              </p>
              <div className="row" style={{ paddingBottom: '20px' }}>
                <div className="col-lg-12">
                  <ul className="list">
                    <li>
                      <i className="fa fa-caret-right" />
                      Provide the highest quality products and services
                    </li>
                    <li>
                      <i className="fa fa-caret-right" />
                      Efficient and timely execution of services
                    </li>
                    <li>
                      <i className="fa fa-caret-right" />
                      Improve the lives of our customers
                    </li>
                    <li>
                      <i className="fa fa-caret-right" />
                      Support and advocate for our clients and customers
                    </li>
                    <li>
                      <i className="fa fa-caret-right" />
                      Be a positive force in our community
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="choose-right">
              <div className="video-image" />
            </div>
          </div>
        </SectionContent>
      </Section>

      <ParallaxSection backgroundImage={ctaBackgroundImage}>
        <CallToAction to="/contact" buttonText="Send Us An Email">
          <h2>Do you need help with your Medical Supplies? Call us @ 956-591-7677</h2>
          <p>
            Or drop by our office on Bentsen Palm Drive in Palmview TX. Our staff is available M-F from 8 am to 5 pm
          </p>
        </CallToAction>
      </ParallaxSection>

      <Section className="portfolio">
        <SectionTitle>
          <h2>We Provide All The Medical Supplies You Need</h2>
          <img src="img/section-img.png" alt="#" />
          <p>Our catalog of medical supplies includes all of the following items</p>
        </SectionTitle>
        <SectionContent fluid>
          <div className="col-lg-12 col-12">
            <ScrollingTiles className="portfolio-slider">
              <ScrollingTile>
                <PortfolioImage image={ambulatoryAids} alt="Ambulatory Aids" to="/products/ambulatory-aids" />
              </ScrollingTile>
              <ScrollingTile>
                <PortfolioImage image={bathroomSafety} alt="Bathroom Safety" to="/products/bathroom-safety" />
              </ScrollingTile>
              <ScrollingTile>
                <PortfolioImage image={hospitalBeds} alt="Hospital Beds" to="/products/hospital-beds" />
              </ScrollingTile>
              <ScrollingTile>
                <PortfolioImage
                  image={incontinenceSupplies}
                  alt="Incontinence Supplies"
                  to="/products/incontinence-supplies"
                />
              </ScrollingTile>
              <ScrollingTile>
                <PortfolioImage image={mobility} alt="Mobility" to="/products/mobility" />
              </ScrollingTile>
              <ScrollingTile>
                <PortfolioImage
                  image={nutritionalSupplements}
                  alt="Nutritional Supplements"
                  to="/products/nutritional-supplements"
                />
              </ScrollingTile>
              <ScrollingTile>
                <PortfolioImage image={patientAdls} alt="Patient ALDS" to="/products/patient-alds" />
              </ScrollingTile>
              {/* <ScrollingTile>
                <PortfolioImage image={safety} alt="Safety" to="/products/safety" />
              </ScrollingTile> */}
            </ScrollingTiles>
          </div>
        </SectionContent>
      </Section>

      <section className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Offer Different Products To Improve Your Health</h2>
                <img src="img/section-img.png" alt="#" />
                <p>
                  Our catalog of products is intended to meet all of your medical needs. If you don&apos;t see something
                  you need, let us know and we&apos;ll do our best to get it for you.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-crutch" />
                <h4>
                  <Link to="/products/ambulatory-aids">Ambulatory Aids</Link>
                </h4>
                <p>Single tip canes, small/large base quad canes, walkers, and rollators.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-bathtub" />
                <h4>
                  <Link to="/products/bathroom-safety">Bathroom Safety</Link>
                </h4>
                <p>Shower chairs, benches, shower wands, bedside commodes and more.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-bed" />
                <h4>
                  <Link to="/products/hospital-beds">Hospital Beds</Link>
                </h4>
                <p>
                  Semi/full electric Hopsital beds, gel mattress overlays, trapeze bars, hydraulic lifts, and overbed
                  tables.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-heart" />
                <h4>
                  <Link to="/products/incontinence-supplies">Incontinence Supplies</Link>
                </h4>
                <p>Diapers, pulll ups, poise pads, disposable/reusable underpads, and wipes for infants and adults.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-wheelchair" />
                <h4>
                  <Link to="/products/mobility">Mobility</Link>
                </h4>
                <p>
                  Transport, standard, light weight, and heavy duty wheelchairs as well as programmable and
                  non-programmable power wheelchairs and 3/4 wheel scooters.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-apple" />
                <h4>
                  <Link to="/products/nutritional-supplements">Nutritional Supplements</Link>
                </h4>
                <p>Ensure, Ensure with fiber, Ensure pudding, Glucerna, and Nepro.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-heartbeat" />
                <h4>
                  <Link to="/products/patient-adls">Patient ADLS</Link>
                </h4>
                <p>
                  Digital blood pressure monitors, glucometers, lancets, test strips, gait belts, 3-1 lift chairs, and
                  heavy duty lift chairs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-service">
                <i className="icofont icofont-safety" />
                <h4>
                  <Link to="/products/safety">Safety Products</Link>
                </h4>
                <p>Powder free latex gloves and back support belts in all sizes.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="insurances overlay">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <InsuranceSlider className="insurances-slider">
                <div className="single-insurances">
                  <img src={uhc} alt="United Healthcare" />
                </div>
                <div className="single-insurances">
                  <img src={medicare} alt="Medicare" />
                </div>
                <div className="single-insurances">
                  <img src={medicaid} alt="Medicaid" />
                </div>
                <div className="single-insurances">
                  <img src={driscoll} alt="Driscoll" />
                </div>
                <div className="single-insurances">
                  <img src={molina} alt="Molina" />
                </div>
                <div className="single-insurances">
                  <img src={superior} alt="Superior" />
                </div>
              </InsuranceSlider>
            </div>
          </div>
        </div>
      </div>

      <Section className="contact-us">
        <SectionTitle>
          <h2>Let Us Know How We Can Help</h2>
          <img src="img/section-img.png" alt="#" />
          <p>
            Need help with something. Send us a message and we&apos;ll get back to you as soon as possible. Our staff is
            here to help you with your medical supply needs.
          </p>
        </SectionTitle>
        <SectionContent>
          <div className="col-lg-6 col-md-12 col-12">
            <ContactUsForm buttonText="Let's Chat" />
          </div>
          <div className="col-lg-6 col-md-12 ">
            <div className="appointment-image">
              <img src="img/contact-us.png" alt="#" />
            </div>
          </div>
        </SectionContent>
      </Section>
    </div>
  );
}

export default Home;
