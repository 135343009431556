import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import OwlCarousel from 'react-owl-carousel';

function ScrollingTiles(props) {
  const { className, children } = props;

  return (
    <OwlCarousel
      className={className}
      items={5}
      autoplay
      autoplayTimeout={3500}
      margin={15}
      smartSpeed={400}
      autoplayHoverPause
      loop
      nav={false}
      dots={false}
      responsive={{
        300: {
          items: 1
        },
        480: {
          items: 2
        },
        768: {
          items: 3
        },
        1170: {
          items: 5
        }
      }}
    >
      {children}
    </OwlCarousel>
  );
}

export default ScrollingTiles;
