import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ContactUsForm from '../../components/Shared/ContactUsForm';
import Breadcrumb from '../../components/Shared/Breadcrumb';

import map from '../../images/contact-us-map.png';

class Contact extends PureComponent {
  constructor(props) {
    super(props);

    // eslint-disable-next-line react/no-unused-state
    this.state = { position: undefined };
  }

  render() {
    return (
      <>
        <Breadcrumb>
          <h2>How Can We Help?</h2>
          <ul className="bread-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <i className="icofont-simple-right" />
            </li>
            <li className="active">Contact Us</li>
          </ul>
        </Breadcrumb>
        <section className="contact-us section">
          <Helmet title="Contact Us" />
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-us-left">
                    <img id="myMap" src={map} alt="Ultra Care DME Map" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-us-form">
                    <h2>Send Us A Message</h2>
                    <p>If you have any questions please fell free to contact us.</p>
                    <ContactUsForm buttonText="Send" />
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-info">
              <div className="row">
                <div className="col-lg-4 col-12 ">
                  <div className="single-info">
                    <i className="icofont icofont-ui-call" />
                    <div className="content">
                      <p>+1 (956) 591-7677 (Office)</p>
                      <p>+1 (956) 766-7289 (Fax)</p>
                      <p>info@ultracaredme.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 ">
                  <div className="single-info">
                    <i className="icofont-google-map" />
                    <div className="content">
                      <p>713 N Bentsen Palm Dr</p>
                      <p>Suite H</p>
                      <p>Mission Tx, 78572</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 ">
                  <div className="single-info">
                    <i className="icofont icofont-wall-clock" />
                    <div className="content">
                      <p>
                        Mon-Fri <span className="time-range">8:00 AM - 5:00 PM</span>
                      </p>
                      <p>
                        Sat <span className="time-range">CLOSED</span>
                      </p>
                      <p>
                        Sun <span className="time-range">CLOSED</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Contact;
