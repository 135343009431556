import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ParallaxProvider } from 'react-scroll-parallax';

import configureStore from '../../store';
import Home from '../../views/Home';
import Contact from '../../views/Home/ContactUs';
import About from '../../views/Home/AboutUs';
import Faq from '../../views/Home/Faq';
import Products from '../../views/Products';
import Services from '../../views/Services';
import Insurance from '../../views/Insurance';
import Header from './Header';
import Footer from './Footer';

const store = configureStore();

class Root extends Component {
  async componentDidMount() {
    try {
      // eslint-disable-next-line no-console
      console.log('Info: Root.componentDidMount()');
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  }

  render() {
    return (
      <ParallaxProvider>
        <Provider store={store}>
          <BrowserRouter>
            <div>
              <Helmet titleTemplate="%s - Ultra Care DME" defaultTitle="Durable Medical Supplies" />
              <Header />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/index.html" element={<Home />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/faq" element={<Faq />} />
                <Route exact path="/products/:category?" element={<Products />} />
                <Route exact path="/services" element={<Services />} />
                <Route exact path="/insurance" element={<Insurance />} />
              </Routes>
              <Footer />
            </div>
          </BrowserRouter>
        </Provider>
      </ParallaxProvider>
    );
  }
}

export default Root;
