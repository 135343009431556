import React from 'react';

function InfoRow(props) {
  const { children } = props;

  return (
    <section className="schedule">
      <div className="container">
        <div className="schedule-inner">
          <div className="row">{children}</div>
        </div>
      </div>
    </section>
  );
}

export default InfoRow;
