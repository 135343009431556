import React from 'react';

function Feature(props) {
  const { className, innerClassName, iconClass, children } = props;

  return (
    <div className={className}>
      <div className={`single-features ${innerClassName}`}>
        <div className="signle-icon">
          <i className={iconClass} />
        </div>
        {children}
      </div>
    </div>
  );
}

export default Feature;
