import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as Yup from 'yup';

import { validateDependantPhoneNumber } from './validations/phonenumber';
import { validateDependantEmail } from './validations/email';
import { submitAction } from '../../store/actions/form';

const initialValues = { name: '', phone: '', email: '', message: '' };
const validationSchema = Yup.object().shape(
  {
    name: Yup.string().required('Please provide a first name'),
    phone: validateDependantPhoneNumber(
      'email',
      'Please provide a phone number or email addres',
      'Please enter a valid 10 digit phone number without the country code'
    ),
    email: validateDependantEmail('phone'),
    message: Yup.string().required("Please tell us what you'd like to discuss")
  },
  ['phone', 'email']
);

function ContactUsForm(props) {
  const { className, buttonText } = props;

  const handleSubmit = useCallback(async (values, actions) => {
    const { submitFormAction } = props;

    await submitFormAction({
      type: 'Contact Us',
      fields: values
    });

    actions.resetForm({ values: initialValues });
    actions.setSubmitting(false);
    toast.success('Your message was submitted. Someone will contact you soon.');
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        isInitialValid={false}
      >
        {({ isValidating, isSubmitting, isValid }) => (
          <div className={`form ${className || ''}`}>
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <Field type="text" name="name" placeholder="Name" />
                    <ErrorMessage name="name" component="div" className="error" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="form-group">
                    <Field type="text" name="phone" placeholder="Phone" />
                    <ErrorMessage name="phone" component="div" className="error" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <Field type="email" name="email" placeholder="Email" />
                    <ErrorMessage name="email" component="div" className="error" />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="form-group">
                    <Field as="textarea" name="message" placeholder="Write Your Message Here....." />
                    <ErrorMessage name="message" component="div" className="error" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-5 col-md-4 col-12">
                  <div className="form-group">
                    <div className="button">
                      <button type="submit" className="btn" disabled={isValidating || isSubmitting || !isValid}>
                        {buttonText}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = (state) => ({
  form: state.form
});

const mapDispatchToProps = (dispatch) => ({
  submitFormAction: (params) => dispatch(submitAction(params))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContactUsForm);
