import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SectionContent from '../../components/Shared/SectionContent';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Section from '../../components/Shared/Section';

import medicare from '../../images/insurance/medicare-color.png';
import medicaid from '../../images/insurance/medicaid-color.png';
import driscoll from '../../images/insurance/driscoll-color.png';
import molina from '../../images/insurance/molina-color.png';
import superior from '../../images/insurance/superior-color.png';
import uhc from '../../images/insurance/uhc-color.png';
import SectionTitle from '../../components/Shared/SectionTitle';

class Insurance extends PureComponent {
  render() {
    return (
      <>
        <Helmet title="Insurance" />
        <Breadcrumb>
          <h2>Accepted Insurances</h2>
          <ul className="bread-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <i className="icofont-simple-right" />
            </li>
            <li className="active">Insurance</li>
          </ul>
        </Breadcrumb>
        <section className="insurance section">
          <div className="container">
            <div className="inner">
              <div className="row" />
              <Section className="services">
                <SectionTitle>
                  <p>Ultra Care DME proudly accepts the following insurances:</p>
                </SectionTitle>
                <SectionContent>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                      <h4>
                        <a href="https://www.medicare.gov" target="_blank" rel="noreferrer">
                          <img src={medicare} alt="Medicare" />
                        </a>
                      </h4>
                      <p>
                        Medicare is health insurance for people 65 or older. You may be eligible to get Medicare earlier
                        if you have a disability, End-Stage Renal Disease (ESRD), or ALS (also called Lou Gehrig&apos;s
                        disease).
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                      <h4>
                        <a href="https://www.medicaid.gov" target="_blank" rel="noreferrer">
                          <img src={medicaid} alt="Medicaid" />
                        </a>
                      </h4>
                      <p>
                        Medicaid provides health coverage to low-income people and is one of the largest payers for
                        health care in the United States.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                      <h4>
                        <a href="https://www.driscollhelthplan.com" target="_blank" rel="noreferrer">
                          <img src={driscoll} alt="Driscoll Health Plan" />
                        </a>
                      </h4>
                      <p>
                        Driscoll Health Plan is a non-profit, community-based health insurance plan offering health care
                        coverage to the communities of South Texas. Their insurance products include STAR Medicaid, STAR
                        Kids, CHIP and CHIP Perinatal.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                      <h4>
                        <a href="https://www.molinahealthcare.com/" target="_blank" rel="noreferrer">
                          <img src={molina} alt="Molina Healthcare" />
                        </a>
                      </h4>
                      <p>
                        Molina Healthcare, Inc., a FORTUNE 500 company, provides managed health care services under the
                        Medicaid and Medicare programs and through the state insurance Marketplace. Through their
                        locally operated health plans, Molina Healthcare serves approximately 5.1 million members.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                      <h4>
                        <a href="https://www.superiorhealthplan.com//" target="_blank" rel="noreferrer">
                          <img src={superior} alt="Superior Healthplan" />
                        </a>
                      </h4>
                      <p>
                        Superior works with Texas Health and Human Services (HHS) to offer STAR (Medicaid), Children’s
                        Health Insurance Program (CHIP), STAR+PLUS, STAR Kids, and STAR Health. They also offer Medicare
                        coverage as well as Ambetter from Superior HealthPlan.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="single-service">
                      <h4>
                        <a href="https://www.uhc.com" target="_blank" rel="noreferrer">
                          <img src={uhc} alt="United Healthcare" />
                        </a>
                      </h4>
                      <p>
                        United Healthcare offers health benefit plans for all ages, all lifestyles and all kinds of
                        budgets — including member programs for individuals and families, people with health insurance
                        through work, and Medicare and Medicaid beneficiaries.
                      </p>
                    </div>
                  </div>
                </SectionContent>
              </Section>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Insurance;
