import React from 'react';

import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

function CallToAction(props) {
  const { className, backgroundImage, children } = props;

  return (
    <ParallaxBanner className={`call-action ${className}`}>
      <ParallaxBannerLayer className="overlay" image={backgroundImage} speed={-20} />
      {children}
    </ParallaxBanner>
  );
}

export default CallToAction;
