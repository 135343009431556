import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import SectionContent from '../../components/Shared/SectionContent';
import SectionTitle from '../../components/Shared/SectionTitle';
import Breadcrumb from '../../components/Shared/Breadcrumb';
import Section from '../../components/Shared/Section';

class Services extends PureComponent {
  render() {
    return (
      <>
        <Helmet title="Services" />
        <Breadcrumb>
          <h2>Service Offerings</h2>
          <ul className="bread-list">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <i className="icofont-simple-right" />
            </li>
            <li className="active">Services</li>
          </ul>
        </Breadcrumb>
        <Section className="services">
          <SectionTitle>
            <p>
              Ultra Care DME prides itself in providing our service in the more efficient and professional manner
              possible. We go above and beyond to ensure that all of our staff members are properly trained and conduct
              themselves with the upmost professionalism.
            </p>
          </SectionTitle>
          {/* <SectionContent>
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-6">
                    <ul className="list">
                      <li>
                        <i className="fa fa-caret-right" />
                        Provide the highest quality products and services
                      </li>
                      <li>
                        <i className="fa fa-caret-right" />
                        Efficient and timely execution of services
                      </li>
                      <li>
                        <i className="fa fa-caret-right" />
                        Improve the lives of our customers
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="list">
                      <li>
                        <i className="fa fa-caret-right" />
                        Support and advocate for our clients and customers
                      </li>
                      <li>
                        <i className="fa fa-caret-right" />
                        Be a positive force in our community
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p />
            </div>
          </SectionContent> */}
        </Section>
      </>
    );
  }
}

export default Services;
