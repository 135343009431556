import { isEmpty } from 'validator';
import * as Yup from 'yup';

export const defaultRequiredMessage = 'Please enter your email address';
export const defaultValidityMessage = 'Please enter a valid email address';

export const validateDependantEmail = (
  otherFieldName,
  requiredMessage = defaultRequiredMessage,
  validityMessaage = defaultValidityMessage
) =>
  Yup.string()
    .email(validityMessaage)
    .when(otherFieldName, {
      is: (value) => !value || isEmpty(value, { ignore_whitespace: true }),
      then: (schema) => schema.required(requiredMessage),
      otherwise: (schema) => schema.optional()
    });

export const validateRequiredEmail = (
  requiredMessage = defaultRequiredMessage,
  validityMessaage = defaultValidityMessage
) => Yup.string().email(validityMessaage).required(requiredMessage);
