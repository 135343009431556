import React from 'react';

function Breadcrumb(props) {
  const { children } = props;

  return (
    <div className="breadcrumbs overlay">
      <div className="container">
        <div className="bread-inner">
          <div className="row">
            <div className="col-12">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Breadcrumb;
