import React from 'react';

function HeroSlide(props) {
  const { backgroundImage, children } = props;

  return (
    <div className="single-slider" style={{ backgroundImage }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="text">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSlide;
