import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import OwlCarousel from 'react-owl-carousel';

function ScrollingTiles(props) {
  const { className, children } = props;

  return (
    <OwlCarousel
      className={className}
      autoplay
      autoplayTimeout={1000}
      margin={10}
      smartSpeed={300}
      autoplayHoverPause
      loop
      nav
      dots
      responsive={{
        300: {
          items: 1
        },
        480: {
          items: 2
        },
        768: {
          items: 3
        },
        1170: {
          items: 4
        },
        1500: {
          items: 5
        },
        1800: {
          items: 7
        }
      }}
    >
      {children}
    </OwlCarousel>
  );
}

export default ScrollingTiles;
