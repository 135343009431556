import React from 'react';

function SectionTitle(props) {
  const { className, children } = props;

  return (
    <div className="container">
      <div className="row">
        <div className={`col-lg-12 ${className}`}>
          <div className="section-title">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default SectionTitle;
