/* eslint-disable import/no-extraneous-dependencies */
import Amplify from '@aws-amplify/core';
import axios from 'axios';

export const ENDPOINT_TYPE = {
  INTERNAL: 'internal',
  PUBLIC: 'public',
  AUTH: 'auth'
};

Amplify.configure({
  aws_cognito_identity_pool_id: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AMPLIFY_REGION,
  aws_user_pools_id: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AMPLIFY_AUTH_APP_ID,
  oauth: {
    domain: `${process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_DOMAIN}.auth.us-east-1.amazoncognito.com`,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_AMPLIFY_AUTH_SIGNIN_REDIRECT_URL,
    redirectSignOut: process.env.REACT_APP_AMPLIFY_AUTH_SIGNOUT_REDIRECT_URL,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
});

const endpointUrl = (type, url) =>
  [process.env.REACT_APP_API_URL, type, process.env.REACT_APP_API_VERSION, url].join('/');

// const internalEndpointUrl = (url) => endpointUrl(ENDPOINT_TYPE.INTERNAL, url);
const publicEndpointUrl = (url) => endpointUrl(ENDPOINT_TYPE.PUBLIC, url);
// const authEndpointUrl = (url) => endpointUrl(ENDPOINT_TYPE.AUTH, url);

export default {
  form: {
    submit: (params) => axios.post(publicEndpointUrl('forms/submissions'), params).then((response) => response.data)
  }
};
